import React, { useState, useEffect } from "react";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import { Elements } from "@stripe/react-stripe-js";
import API from "../../api";
import SignupSuccess from "./SignupSuccess";
import setAxiosAuthToken from "../../utils/setAxiosAuthToken";
import { getQueryVariable } from "../../utils/helpers";
import Blob from "../Canvas/Blob";
import { MultiStepForm, Step } from "react-multi-form";
import { prices, stripePromise, stripeOptions } from "../../services/stripe";
import { useRouteMatch } from 'react-router-dom';

const Signup = ({
  isReferred,
  producerName,
  refArtisteId,
  refUserId,
  partnerName,
  partnerData,
  plan,
}) => {
  const [page, setPage] = useState(1);
  const [eventPage, setEventPage] = useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(prices[9]);

  const [formData, setFormData] = useState({
    plan: plan,
    cardName: "",
    paymentMethod: "",
    firstname: "",
    lastname: "",
    password: "",
    email: "",
    phone: "",
    secretCode: "",
    isReferred: false,
    producer: "",
    refArtisteId: "",
    refUserId: "",
    partnerName,
    userType: plan === 'sound' ? 'artiste' : 'listener',
  });

  const [submissionError, setSubmissionError] = useState("");
  const [loading, setLoading] = useState(false);

  const match = useRouteMatch('/:userType');
  let type = match ? match.params.userType : 'artistes'; // default to 'artistes'
  if (type !== 'artistes' && type !== 'stream') {
    type = 'artistes';
  } else if (type === 'stream') {
    type = 'users';
  }
  const signupUser = (customerId, subscriptionId) => {
    API.signUp({
      stripeCustomerId: customerId,
      ...formData,
      subscriptionId,
    },type)
      .then(async (res) => {
        const sharedSongId = localStorage.getItem("sharedSongId");
        if (sharedSongId) {
          setAxiosAuthToken(res.token);
          await API.saveSong(sharedSongId, "save");
        }

        if (eventPage) {
          window.location.href = "https://loudarmysounds.netlify.app/verified";
        } else {
          setPage(3);
        }
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
        setSubmissionError(err.data.message);
      });
  };

  useEffect(() => {
    if (isReferred && producerName) {
      setFormData((prev) => ({
        ...prev,
        isReferred: true,
        producer: producerName,
      }));
    }
    if (refArtisteId) {
      setSubscriptionPrice(prices[8]);
      setFormData((prev) => ({ ...prev, refArtisteId }));
    }
    if (refUserId) {
      setSubscriptionPrice(prices[8]);
      setFormData((prev) => ({ ...prev, refUserId }));
    }
    if (partnerName) {
      setFormData((prev) => ({ ...prev, plan: "partner" }));
    }
  }, [isReferred, producerName, refArtisteId, refUserId, partnerName]);

  useEffect(() => {
    setEventPage(getQueryVariable("event"));
  }, []);

  return (
    <div className="signup">
      <div style={{ marginBottom: "40px", zIndex: "100" }}>
        <MultiStepForm activeStep={page} accentColor={"white"}>
          <Step label="PAYMENT"></Step>
          <Step label="SET UP"></Step>
          <Step label="DONE"></Step>
        </MultiStepForm>
      </div>
      {eventPage && <Blob></Blob>}
      {page === 1 && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <SignupStep1
            setPage={setPage}
            formData={formData}
            setFormData={setFormData}
            setSubmissionError={setSubmissionError}
            eventPage={eventPage}
            partnerName={partnerName}
            partnerData={partnerData}
          />
        </Elements>
      )}
      {page === 2 && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <SignupStep2
            setPage={setPage}
            formData={formData}
            setFormData={setFormData}
            signupUser={signupUser}
            subscriptionPrice={subscriptionPrice}
            setSubmissionError={setSubmissionError}
          />
        </Elements>
      )}
      {page === 3 && <SignupSuccess formData={formData} />}
    </div>
  );
};

export default Signup;
