import axios from 'axios';
import setAxiosAuthToken from '../utils/setAxiosAuthToken';
import {
  cancelSubscription,
  reactivateSoundSubscription,
  createProducerChallengeSubscription,
  createPaymentIntent,
} from './subscriptions';
import admin from './admin';
import song from './song';
import organization from './organization';

// export const baseUri = process.env.REACT_APP_API_URL;
export const baseUri = 'https://app.loud.army/api/v1';
//export const baseUri = 'http://localhost:5000/api/v1';

const loadUser = async () => {
  const token = localStorage.getItem('auth-token');
  let userType = localStorage.getItem('userType');
  if (userType === 'listener') {
    userType = 'users';
  } else {
    userType = 'artistes';
  }
  setAxiosAuthToken(token);
  try {
    const response = await axios.get(`${baseUri}/${userType}/get-user`);
    return response.data.user;
  } catch (err) {
    throw err.response;
  }
};

const signUp = async (formData,userType) => {
  try {
    const response = await axios.post(`${baseUri}/${userType}/signup`, formData);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const processSignupPayment = async (formData) => {
  try {
    const response = await axios.post(`${baseUri}/subscriptions/new`, formData);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const signIn = async (formData, userType) => {
  try {
    const response = await axios.post(`${baseUri}/${userType}/signin`, {
      ...formData,
    });
    localStorage.setItem("userType", response.data.user.userType);
    return response.data;
  } catch (err) {
    console.log(err);

    throw err.response;
  }
};

const fetchSongs = async (pageNum, filter, producerId,userType='artiste',type='beat') => {
  try {
    if (filter === 'popular') {
      const response = await axios.get(
        `${baseUri}/songs/?filter=popular&page=${pageNum}&userType=${userType}&type=${type}`
      );
      return response.data.songs;
    } else if (filter === 'saved') {
      const response = await axios.get(`${baseUri}/songs?filter=saved&userType=${userType}&type=${type}`);
      return response.data.songs;
    } else if (filter === 'recent') {
      const response = await axios.get(
        `${baseUri}/songs/?filter=recent&page=${pageNum}&userType=${userType}&type=${type}`
      );
      return response.data.songs;
    } else {
      const response = await axios.get(
        `${baseUri}/songs/?filter=producer&producerId=${producerId}&page=${pageNum}&userType=${userType}&type=${type}`
      );
      return response.data.songs;
    }
  } catch (error) {
    console.log(error.response);
    throw error;
  }
};

const incrementSongPlayCount = async (songId) => {
  try {
    await axios.post(`${baseUri}/songs/${songId}/increment-play`);
  } catch (err) {
    console.log(err.response);
  }
};

const saveSong = async (songId, saveType) => {
  try {
    let userType = localStorage.getItem('userType');
    if (saveType === 'save') {
      await axios.post(`${baseUri}/songs/${songId}/save?userType=${userType}`);
    } else if (saveType === 'remove') {
      await axios.delete(`${baseUri}/songs/${songId}/save?userType=${userType}`);
    }
  } catch (err) {
    console.log(err.response);
  }
};

const checkIfSongIsSaved = async (songId,userType) => {
  try {
    const response = await axios.get(`${baseUri}/songs/${songId}/check-saved?userType=${userType}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const exportSongs = async (data,userType = 'artiste') => {
  try {
    await axios.post(`${baseUri}/songs/download?userType=${userType}`, { ...data });
  } catch (err) {
    console.log(err.response);
  }
};

const getSong = async (id) => {
  try {
    const response = await axios.get(`${baseUri}/songs/${id}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

const completedRegistration = async () => {
  try {
    let userType = localStorage.getItem('userType');
    let url = userType === 'listener' ? 'users' : 'artistes';
    console.log(userType)
    await axios.post(`${baseUri}/${url}/complete-registration?userType=${userType}`);
  } catch (err) {
    throw err.response;
  }
};
const getProducerSongsDownloaded = async () => {
  try {
    const response = await axios.get(`${baseUri}/producers/get-downloads`);
    return response.data.downloads;
  } catch (err) {
    throw err.response;
  }
};
const checkSecretCode = async (data) => {
  try {
    const response = await axios.post(`${baseUri}/artistes/check-secret-code`, {
      ...data,
    });
    return response.data.secretCode;
  } catch (err) {
    throw err.response;
  }
};

const checkIfProducerNicknameExists = async (name) => {
  try {
    const response = await axios.post(
      `${baseUri}/producers/check-nickname/${name}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const validateArtisteID = async (id) => {
  try {
    const response = await axios.post(`${baseUri}/artistes/validate-id/${id}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const validateUserID = async (id) => {
  try {
    const response = await axios.post(`${baseUri}/users/validate-id/${id}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const producerSignup = async (data) => {
  try {
    const response = await axios.post(`${baseUri}/producers/signup`, {
      ...data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const uploadSong = async (data) => {
  try {
    const response = await axios.post(`${baseUri}/songs`, data);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getProducerRevenue = async () => {
  try {
    const response = await axios.get(`${baseUri}/producers/get-revenue`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const sendProducerEmailConfirmation = async () => {
  try {
    const response = await axios.post(
      `${baseUri}/producers/send-email-confirmation`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const confirmProducerEmail = async (token) => {
  try {
    const response = await axios.post(
      `${baseUri}/producers/confirm-email/${token}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const handleForgotPassword = async (data, user) => {
  try {
    let url;
    if ( user === 'listener'){
      url = 'users';
    }else if (user === 'artistes'){
      url = 'artistes';
    }else if (user === 'producer'){
      url = 'producers';
    }
    const response = await axios.post(`${baseUri}/${url}/forgot-password`, {
      ...data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const handleResetPassword = async (data, user, token) => {
  try {
    let url;
    if ( user === 'listener'){
      url = 'users';
    }else if (user === 'artiste'){
      url = 'artistes';
    }else if (user === 'producer'){
      url = 'producers';
    }
    const response = await axios.post(
      `${baseUri}/${url}/reset-password/${token}`,
      {
        ...data,
      }
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const getProducerSongs = async (type) => {
  try {
    const response = await axios.get(`${baseUri}/producers/get-songs`, { params: { type }});
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const reportSong = async (data) => {
  try {
    let userType = localStorage.getItem('userType');
    const response = await axios.post(`${baseUri}/songs/report?userType=${userType}`, { ...data });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const adminSignup = async (data) => {
  try {
    const response = await axios.post(`${baseUri}/admins/signup`, {
      ...data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const adminGetSummary = async () => {
  try {
    const response = await axios.get(`${baseUri}/admins/summary`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const adminCreateSecretServiceCodes = async (data) => {
  try {
    const response = await axios.post(
      `${baseUri}/admins/create-secret-service`,
      { ...data }
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const createArtisteReferrals = async (data) => {
  try {
    const response = await axios.post(`${baseUri}/artiste-referrals`, {
      ...data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const getArtisteReferrals = async () => {
  try {
    const response = await axios.get(`${baseUri}/artiste-referrals`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const createUserReferrals = async (data) => {
  try {
    let userType = localStorage.getItem('userType');
    const response = await axios.post(`${baseUri}/user-referrals?userType=${userType}`, {
      ...data,
    });
    return response.data;
  } catch (err) {
    throw err.response;
  }
};
const getUserReferrals = async () => {
  try {
    let userType = localStorage.getItem('userType');
    const response = await axios.get(`${baseUri}/user-referrals?userType=${userType}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getSharedSongList = async (producerName,pageType) => {
  let type;
  if (pageType === 'stream') {
    type = 'song';
  } else {
    type = 'beat';

  }
  try {
    const response = await axios.get(
      `${baseUri}/producers/songs?nickname=${producerName}&type=${type}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const getFavouriteProducers = async () => {
  try {

    let userType = localStorage.getItem('userType');
    let user = parseUserType(userType);
    const response = await axios.get(`${baseUri}/${user}/favourite-producers?userType=${userType}`);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const addFavouriteProducers = async (id) => {
  try {
    let userType = localStorage.getItem('userType');
    let user = parseUserType(userType);
    const response = await axios.post(
      `${baseUri}/${user}/favourite-producers?producerId=${id}&userType=${userType}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

const deleteFavouriteProducer = async (id) => {
  try {
    let userType = localStorage.getItem('userType');
    let user = parseUserType(userType);
    const response = await axios.delete(
      `${baseUri}/${user}/favourite-producers?producerId=${id}&userType=${userType}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

function parseUserType(userType) {
  if (userType === 'listener') {
    return 'users';
  } else {
    return 'artistes';
  }
}


const API = {
  loadUser,
  signUp,
  signIn,
  fetchSongs,
  incrementSongPlayCount,
  saveSong,
  exportSongs,
  completedRegistration,
  processSignupPayment,
  getProducerSongsDownloaded,
  checkSecretCode,
  checkIfProducerNicknameExists,
  producerSignup,
  uploadSong,
  getProducerRevenue,
  sendProducerEmailConfirmation,
  confirmProducerEmail,
  handleForgotPassword,
  handleResetPassword,
  getProducerSongs,
  reportSong,
  adminSignup,
  adminGetSummary,
  adminCreateSecretServiceCodes,
  getSong,
  checkIfSongIsSaved,
  cancelSubscription,
  createPaymentIntent,
  reactivateSoundSubscription,
  createProducerChallengeSubscription,
  validateArtisteID,
  validateUserID,
  createArtisteReferrals,
  getArtisteReferrals,
  createUserReferrals,
  getUserReferrals,
  getSharedSongList,
  getFavouriteProducers,
  addFavouriteProducers,
  deleteFavouriteProducer,
  ...admin,
  ...song,
  ...organization,
};

export default API;
